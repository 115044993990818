import axios from 'axios';
import { ethers } from 'ethers';
import PassbookCard from '../../components/Cromo';
import React, { useEffect, useState } from 'react';
import { management_api } from '../../services/api';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

import { PopUp, PopUpInfo } from 'components/popUp';
import SideBar from 'components/SideBar';
import Footer from 'components/footer';
import Slider from 'react-slick';

import './styles.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PopUpAlert from 'components/PopUpAlert';
const rarities: any = { LPS: "LPS", Green: "Verde", Blue: "Azul", Yellow: 'Amarelo', Purple: 'Roxo', Grey: 'Cinza', Repetidos: 'Repetidos' };

interface NFTMetaDataProps {
    name: string;
    description: string;
    image: string;
    edition: number;
    attributes: Array<{
        trait_type: string;
        value: string
    }>
}

interface NFTProps {
    chain: string;
    symbol: string;
    tokenId: string;
    metadata: NFTMetaDataProps
}

type Window = {
    ethereum?: any;
    innerWidth: number;
}

declare const window: Window;

export default function Passbook() {

    const [defaultAccount, setDefaultAccount] = useState(null);
    const [signer, setDefaultSigner] = useState<ethers.Signer>();
    const [isPopUpOpen, setIsPopUpOpen] = useState<boolean>(false);
    const [cardProps, setCardProps] = useState<PopUpInfo>({
        age: '',
        position: '',
        imageURI: '',
        name: '',
        number: '',
        onClick: () => { },
        rarity: ''
    })
    const [nfts, setNFTS] = useState<any[]>([]);
    const [popUpForm, setPopUpForm] = useState({
        error: true,
        timer: 6000,
        message: '',
        title: '',
        links: '',
        show: false
    });
    const [btnSelected, setBtnSelected] = useState<number>(0);
    let sliderRef: Slider | null;
    const settings = {
        dots: true,
        infinite: false,
        className: "element",
        draggable: true,
        afterChange: setBtnSelected,
    };

    const connectwalletHandler = () => {
        try {
            if (window.ethereum) {
                if (!signer) {
                    const provider = new ethers.providers.Web3Provider(window.ethereum)
                    provider.send("eth_requestAccounts", []).then(async () => {
                        await accountChangedHandler(provider.getSigner()).then(async (address) => {
                            await getUserNFTs(address);
                        });
                    })
                }
            } else {
                throw new Error('Por favor instale a metamask através do seguinte link:\n')
            }
        } catch (error: any) {
            setPopUpForm({
                ...popUpForm,
                message: `${error.message} \n `,
                title: 'Metamask não encontrada',
                links: 'https://metamask.io/download/',
                show: true
            })
        }
    }

    const accountChangedHandler = async (newAccount: any) => {
        const address = await newAccount.getAddress();
        setDefaultAccount(address);
        setDefaultSigner(signer);

        return address;
    }

    async function getUserNFTs(walletAddress?: string) {
        let cromos: any[] = [];
        try {
            const response = await management_api.post('/eth/wallets/listNfts', {
                walletAddress: defaultAccount || walletAddress,
                tokenAddress: '0x06f53b183e18467e98f44db5cb3a23f7d829796e'
                // walletAddress: '0xFe976500112248E4817adb71FDda22AECDCD510C'
            });
            
            setNFTS(response.data);
        } catch (error: any) {
            setPopUpForm(popup => ({
                ...popup,
                message: error.message,
                title: "Something went wrong",
                show: true
            }))
        }
    }

    function populateRarities(arr: any[]) {
        for (let index = arr.length; arr.length < 20; index++) {
            arr.push(<PassbookCard onclick={() => { }}
                image={'undefined.svg'}
                name={''}
                number={''}
                key={index} />);
        }
    }

    function fillPassBookCard(name: string, number: string = '-', image: string, rarity: string, position: string, age: string, index?: number) {
        return <PassbookCard onclick={() => {
            setCardProps({
                name: name,
                number: number,
                imageURI: image,
                rarity: rarity,
                age: age || '-',
                position: position || '-'
            });
            setIsPopUpOpen(true)
        }}
            image={image}
            name={name}
            number={number}
            key={index} />
    }

    function getDuplicated(arraySource: any[]): any[] {
        let tmp: any[] = [];

        for (let i = 0; i < arraySource.length - 1; i++) {
            for (let j = i + 1; j < arraySource.length; j++) {
                if (arraySource[i] === arraySource[j] && !tmp.includes(arraySource[i])) {
                    tmp.push(arraySource[i]);
                }
            }
        }

        return tmp;
    }

    function removeDuplicated(arraySource: any[]) {
        let tmp: any[] = [];

        for (let i = 0; i < arraySource.length; i++) {
            for (let j = 1; j < arraySource.length - 1; j++) {
                if (arraySource[i] !== arraySource[j] && !tmp.includes(arraySource[i])) {
                    tmp.push(arraySource[i]);
                }
            }
        }
        return tmp;
    }

    function distributeByRarities(arrSource: any[], rarity: keyof typeof rarities): any[] {
        let tmp: any[] = [];
        if (rarity === 'Repetidos') {
            const tmpDouble = getDuplicated(arrSource);

            tmpDouble.map((cromo: NFTProps, number: number) => {
                tmp.push(fillPassBookCard(cromo.metadata.attributes[1].value, cromo.metadata.attributes[4]?.value, cromo.metadata.image, rarities[cromo.metadata.attributes[0].value], cromo.metadata.attributes[2]?.value, cromo.metadata.attributes[3]?.value, number * 300))
            })
        } else {
            rarity === 'LPS' ?
                removeDuplicated(arrSource).map((cromo: NFTProps, number: number) => {
                    tmp.push(fillPassBookCard(cromo.metadata.attributes[1].value, cromo.metadata.attributes[4]?.value, cromo.metadata.image, rarities[cromo.metadata.attributes[0].value], cromo.metadata.attributes[2]?.value, cromo.metadata.attributes[3]?.value, number * 200))
                }) : (
                    arrSource.map((cromo: NFTProps, number: number) => {
                        cromo.metadata.attributes[0].value === rarity && tmp.push(fillPassBookCard(cromo.metadata.attributes[1].value, cromo.metadata.attributes[4]?.value, cromo.metadata.image, rarities[cromo.metadata.attributes[0].value], cromo.metadata.attributes[2]?.value, cromo.metadata.attributes[3]?.value, number * 100))
                    })
                )
        };

        populateRarities(tmp);

        return tmp;
    }

    /**
     * Função de busca e preenchimento de cromos
     * @param index recebe qual o nivel de raridade
     * @returns retorna o array de cromos a preencher
     */
    function getCromos(index: number) {
        switch (index) {
            case 1:
                return distributeByRarities(nfts, 'LPS');
            case 2:
                return distributeByRarities(nfts, 'Green');
            case 3:
                return distributeByRarities(nfts, 'Blue');
            case 4:
                return distributeByRarities(nfts, 'Yellow');
            case 5:
                return distributeByRarities(nfts, 'Purple');
            case 6:
                return distributeByRarities(nfts, 'Grey');
            case 7:
                return distributeByRarities(nfts, 'Repetidos');
        }
    }

    const paginas = Object.keys(rarities).map((data, index) =>
        <div className='element' key={index}>
            <div className='PassbookCards' >
                {getCromos(index + 1)}
            </div>
        </div>
    )

    useEffect(() => {
        connectwalletHandler();
    }, []);

    useEffect(() => {
        if (defaultAccount) {
            getUserNFTs();
        }
    }, [defaultAccount])

    return (
        <div id='passbook'>
            <PopUpAlert
                show={popUpForm.show}
                timer={popUpForm.timer}
                closeAnimation={() => { setPopUpForm(popup => ({ ...popup, show: false })) }}
                title={popUpForm.title}
                message={popUpForm.message}
                links={popUpForm.links}
                error={popUpForm.error}
            />
            <SideBar home={false} />

            <div className='navBar'>
                <div className='container-2'>
                    <IoIosArrowBack onClick={() => { sliderRef?.slickPrev() }} />
                    <button className={btnSelected === 0 ? 'selected' : ''} onClick={() => { sliderRef?.slickGoTo(0) }}>{window.innerWidth <= 968 ? "1" : "Caderneta"}</button>
                    <button className={btnSelected === 1 ? 'selected' : ''} onClick={() => { sliderRef?.slickGoTo(1) }}>{window.innerWidth <= 968 ? "2" : "Verde"}</button>
                    <button className={btnSelected === 2 ? 'selected' : ''} onClick={() => { sliderRef?.slickGoTo(2) }}>{window.innerWidth <= 968 ? "3" : "Azul"}</button>
                    <button className={btnSelected === 3 ? 'selected' : ''} onClick={() => { sliderRef?.slickGoTo(3) }}>{window.innerWidth <= 968 ? "4" : "Ouro"}</button>
                    <button className={btnSelected === 4 ? 'selected' : ''} onClick={() => { sliderRef?.slickGoTo(4) }}>{window.innerWidth <= 968 ? "5" : "Roxo"}</button>
                    <button className={btnSelected === 5 ? 'selected' : ''} onClick={() => { sliderRef?.slickGoTo(5) }}>{window.innerWidth <= 968 ? "6" : "Comum"}</button>
                    <button className={btnSelected === 6 ? 'selected' : ''} onClick={() => { sliderRef?.slickGoTo(6) }}>{window.innerWidth <= 968 ? "Rep." : "Repetidos"}</button>
                    <IoIosArrowForward onClick={() => { sliderRef?.slickNext() }} />
                </div>
            </div>
            <Slider ref={ref => (sliderRef = ref)} {...settings}  >
                {paginas}
            </Slider>
            {isPopUpOpen ? <PopUp imageURI={cardProps?.imageURI}
                name={cardProps?.name}
                number={cardProps?.number}
                age={cardProps.age}
                key={0}
                position={cardProps.position}
                rarity={cardProps?.rarity}
                onClick={() => { setIsPopUpOpen(!isPopUpOpen) }}
            /> : ""}

            <Footer />
        </div>
    )
}
