import React, { useState } from 'react'
import { FaMinus, FaPlus } from 'react-icons/fa';
import './styles.css'

export default function FaQ() {
  const [isOpen, setIsOpen] = useState<Number>(0);

  let perguntas = [
    {
      question: 'Como faço para comprar um NFT?',
      answer: 'Queremos que esta experiência seja, simples e rápida para ti, por isso, para comprares os NFTs dos LPS basta clicares no botão de comprar e seguir o passo a passo até ao pagamento, uma vez pago os NFTs apareceram na tua carteira.',
    },
    {
      question: 'O que é a Plataforma da ROOTKey?',
      answer: 'A Plataforma da ROOTKey é um sítio onde poderás gerir todas as tuas carteiras e NFTs, tal como trocá-los com os teus amigos.',
    },
    {
      question: 'O que é a Metamask?',
      answer: 'A Metamask é uma ferramenta que possibilita a compra e transferência de NFTs e ativos na blockchain, pode ser usada a partir de uma extensão para o navegador ou a sua aplicação móvel.',
    },
    {
      question: 'Como faço para transferir um cromo para um amigo meu?',
      answer: 'Para transferires um dos teus cromos para os teus amigos, basta teres o endereço da carteira deles e, a seguir, podes usar a Plataforma da ROOTKey ou a Metamask para executar a transferência.',
    },
    {
      question: 'Como crio uma conta na ROOTKey?',
      answer: 'Se não criaste conta no processo de pagamento podes sempre ir a www.platform.notcybersec.com registares-te, e adicionares a carteira onde tens os teus cromos, se esta foi criada a partir do processo de pagamento, tens todas as credenciais necessárias no teu email!',
    },
    {
      question: 'Como crio uma conta na Metamask?',
      answer: 'Para criares uma conta na Metamask basta instalares extensão da metamask no teu navegador, ou instalares a aplicação móvel, e seguires o passo a passo.',
    },
  ]

  const array = perguntas.map((faq, index) => (
    <div className={isOpen === index + 1 ? "faq open" : 'faq'} key={index + 1} onClick={() => setIsOpen(isOpen === index + 1 ? 0 : index + 1)}>
      <div>
        <p>{faq.question}</p>
        {isOpen === index + 1 ? <FaMinus /> : <FaPlus />}
      </div>
      <p className="faq-answer">{faq.answer}</p>
    </div>
  ))

  return (
    <div id='faq'>
      <div className='container'>
        <h1 className='title'> FAQ's </h1>
        <div className="pergunta">
          {array}
        </div>
      </div>
    </div>
  );
}
