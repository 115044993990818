import { Route, BrowserRouter, Routes as Switch } from "react-router-dom";
import Home from './pages/Home';
import Passbook from './pages/Passbook';

const Routes = () => {
    return(
        <BrowserRouter>
            <Switch>
                <Route path="/*" element={<Home />}/>
                <Route path="/caderneta" element={<Passbook />} />
            </Switch>
        </BrowserRouter>
    )
}

export default Routes;
