import React from 'react';
import './styles.css';

import Hero from '../../components/hero';
import SideBar from '../../components/SideBar';
import NFT from '../../components/NFT';
import Footer from '../../components/footer';
import Buy from '../../components/Buy';
import FaQ from '../../components/FaQ';
import Raridade from '../../components/Raridade';
import PopUpBuy from 'components/PopUpBuy';


export default function Home() {
    return (
        <div id='Home'>
            <PopUpBuy/>
            <SideBar home />
            <Hero />
            <NFT />
            <Buy />
            <Raridade />
            <FaQ />
            <Footer />
        </div>
    )
}

