import React from 'react';
import { BsPlusSquareDotted } from 'react-icons/bs'
import './styles.css'

interface NFTProps {
  image: string,
  name: string,
  number: string
  onclick: (props: any) => void;
}
export default function Cromo(card: NFTProps) {
  return (
    <>
      {card.image !== 'undefined.svg' ?
       <img onClick={card.onclick} id='card' src={card.image} alt={card.name} key={card.number} /> 
       :
       <div id="card"><BsPlusSquareDotted /></div>}
    </>
  )
}