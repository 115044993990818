import axios from 'axios';

export const management_api = axios.create({
    baseURL: 'https://ncs.westeurope.cloudapp.azure.com/management-service/api-v1/'
    // baseURL: 'http://localhost:3333/api-v1/'
})

export const creation_api = axios.create({
    baseURL: 'https://ncs.westeurope.cloudapp.azure.com/creation-service/api-v1/'
})

export const auth_api = axios.create({
    baseURL: 'https://ncs.westeurope.cloudapp.azure.com/auth-service/api-v1/'
})

export const payments_api = axios.create({
    baseURL: 'https://ncs.westeurope.cloudapp.azure.com/payments-service/api-v1/'
    // baseURL: 'http://localhost:3333/api-v1/'
})
