import React, { FormEvent, useEffect, useState } from "react";

import './styles.css'
import './inputs.css'
import 'aos/dist/aos.css';

import { blockchainInfo, contractAbi, contractAddress, emailRegex } from "utils/constants";

import { AiOutlineCloseCircle } from "react-icons/ai";
import { ethers } from "ethers";
import { usePopUpBuy } from "hook/usePopUpBuy";


import parte1 from '../../assets/lojaoficial1-2.png'
import parte2 from '../../assets/lojaoficial1-1.png'
import { auth_api, creation_api, management_api, payments_api } from "services/api";
import PopUpAlert from "components/PopUpAlert";

type Window = {
    ethereum?: any;
    open?: any;
    location?: any;
}
declare const window: Window;

export default function PopUpBuy() {

    const [signer, setDefaultSigner] = useState<ethers.Signer | null>(null);
    const { changePopUpBuyState, closed } = usePopUpBuy();
    const [metamask, setMetamask] = useState<boolean>(true);
    const [createWallet, setCreateWallet] = useState<boolean>(false);
    const [defaultAccount, setDefaultAccount] = useState({
        address: '',
        balance: '0',
    });
    const [createAccount, setCreateAccount] = useState<boolean>(false);
    const [walletInfo, setWalletInfo] = useState({
        state: false,
        addr: "",
        pk: "",
        email: "",
    });
    const [accountInfo, setAccountInfo] = useState(
        {
            stateCreateAccount: false,
            stateSendEmail: false,
            email: "",
            password: "",
            retypePassword: "",
            walletName: "",
            userName: "",
            description: "",
            termes: "false",
            company: false
        }
    );
    const [popUpForm, setPopUpForm] = useState({
        error: true,
        timer: 6000,
        message: '',
        title: '',
        links: '',
        show: false
    });

    async function checkSmartContractState() {
        try {
            const response = await management_api.post('/eth/sc/checkState', {
                type: 'others',
                subType: 'lps'
            });

            if (!response.data.state) {
                throw new Error('Não existem mais cromos disponíveis para esta coleção!')
            }

            return true;

        } catch (error: any) {
            setPopUpForm({
                ...popUpForm,
                message: error.message,
                title: 'Impossível realizar compra',
                show: true
            })
        }
    }

    async function connectwalletHandler() {
        try {
            if (window.ethereum) {
                if (!signer) {
                    const provider = new ethers.providers.Web3Provider(window.ethereum)
                    provider.send("eth_requestAccounts", []).then(async () => {
                        await accountChangedHandler(provider.getSigner());
                    })
                }
            } else {
                throw new Error('Por favor instale a metamask através do seguinte link:\n')
            }
        } catch (error: any) {
            setPopUpForm({
                ...popUpForm,
                message: `${error.message} \n `,
                title: 'Metamask não encontrada',
                links: 'https://metamask.io/download/',
                show: true
            })
        }
    }

    async function accountChangedHandler(signer: any) {
        const address = await signer.getAddress();
        setDefaultAccount({ address, balance: await signer.getBalance() });
        setDefaultSigner(signer);
    }

    async function handleMintNFT() {
        try {
            if (signer) {
                await checkSmartContractState();
                const sc = new ethers.Contract(contractAddress, contractAbi.abi, signer);
                const a = await sc.functions.awardNFT(defaultAccount.address, {
                    value: ethers.utils.parseEther('5'),
                    gasLimit: 10000000
                }).then(() => window.location.assign('https://nft.leoesdeportosalvo.pt/'));

            }
        } catch (error) {
            console.log(error);
        }
    }

    async function createWalletHandler(event: FormEvent) {
        //Cria uma wallet, pede dinheiro, faz o mint, envia os dados para o mail da pessoa
        try {
            event.preventDefault();

            await checkSmartContractState();

            if (!emailRegex.test(accountInfo.email)) {
                throw new Error('Por favor, insira um email válido antes de continuar');
            }

            const response_wallet = await creation_api.post<{ wallet: { walletAddress: string, privateKey: string } }>('/wallets', {
                name: accountInfo.userName,
                email: accountInfo.email
            });

            const response_payments = await payments_api.post('/checkout', {
                productId: 'price_1MHBbHGVm0yVTkYlVL26p1yv',
                type: 'others',
                subtype: 'lps',
                email: accountInfo.email,
                ownerWallet: response_wallet.data.wallet.walletAddress,
                privateKey: response_wallet.data.wallet.privateKey,
                success_url: 'https://nft.leoesdeportosalvo.pt//caderneta',
                cancel_url: 'https://nft.leoesdeportosalvo.pt//'
            });

            window.open(response_payments.data.url);

        } catch (error: any) {
            setPopUpForm(popup => ({
                ...popup,
                message: error.message,
                title: "Informações Inválidas",
                show: true
            }))
        }
    }

    async function walletInfoHandler(event: FormEvent) {
        //Usa o Addr e a PK do User para fazer mint
        try {
            event.preventDefault();

            if (await checkSmartContractState()) {

                if (!emailRegex.test(walletInfo.email)) {
                    throw new Error('Por favor, insira um email válido antes de continuar');
                }

                if (!blockchainInfo.Eth.address.test(walletInfo.addr)) {
                    throw new Error('Por favor, insira um endereço válido para a sua carteira digital');
                }

                if (walletInfo.pk.length != 64) {
                    throw new Error('A chave privada que inseriu não é válida');
                }

                const response = await payments_api.post('/checkout', {
                    productId: 'price_1MHBbHGVm0yVTkYlVL26p1yv',
                    type: 'others',
                    subtype: 'lps',
                    email: walletInfo.email,
                    walletAddress: walletInfo.addr,
                    privateKey: walletInfo.pk,
                    success_url: 'https://nft.leoesdeportosalvo.pt//caderneta',
                    cancel_url: 'https://nft.leoesdeportosalvo.pt//'
                });

                window.open(response.data.url);
            }

        } catch (error: any) {
            setPopUpForm(popup => ({
                ...popup,
                message: error.message,
                title: "Informações Inválidas",
                show: true
            }))
        }
    }

    async function createAccountHandler(event: FormEvent) {
        // Cria conta, cria wallet, pede dinheiro, faz mint, manda email a notificar do passo a passo para a ROOTKey e integração com a metamask
        try {
            event.preventDefault();

            await checkSmartContractState();

            if (!emailRegex.test(accountInfo.email)) {
                throw new Error('Por favor, insira um email válido antes de continuar');
            }

            await auth_api.post('/register', {
                email: accountInfo.email,
                password: accountInfo.password,
                name: accountInfo.userName,
                company: false,
            });

            const response_login = await auth_api.post('/login', {
                email: accountInfo.email,
                password: accountInfo.password
            });

            const response_wallet = await creation_api.post<{ wallet: { walletAddress: string, privateKey: string } }>('/wallets');

            await auth_api.post('/auth/add-wallet', {
                name: accountInfo.walletName,
                comment: accountInfo.description,
                walletAddress: response_wallet.data.wallet.walletAddress,
                privateKey: response_wallet.data.wallet.privateKey
            }, {
                headers: {
                    'Authorization': `Bearer ${response_login.data.user}`
                }
            });

            const response_payments = await payments_api.post('/checkout', {
                productId: 'price_1MHBbHGVm0yVTkYlVL26p1yv',
                type: 'others',
                subtype: 'lps',
                email: accountInfo.email,
                ownerWallet: response_wallet.data.wallet.walletAddress,
                privateKey: response_wallet.data.wallet.walletAddress,
                success_url: 'https://nft.leoesdeportosalvo.pt/caderneta',
                cancel_url: 'https://nft.leoesdeportosalvo.pt/'
            });

            window.open(response_payments.data.url);
        } catch (error: any) {
            setPopUpForm(popup => ({
                ...popup,
                message: error.response.data.message,
                title: "Informações Inválidas",
                show: true
            }))
        }
    }

    async function metamaskHandler() {
        // Autentica pela metamask e faz mint, se não tiver dinheiro pede
        connectwalletHandler();

        if (signer) {
            await handleMintNFT();
        }
    }

    function clearInputs() {
        setMetamask(true)
        setCreateWallet(false)
        setCreateAccount(false)
        setWalletInfo(
            {
                state: false,
                addr: "",
                pk: "",
                email: ""
            }
        );
        setAccountInfo(
            {
                stateCreateAccount: false,
                stateSendEmail: false,
                email: "",
                password: "",
                retypePassword: "",
                walletName: "",
                userName: "",
                description: "",
                termes: "false",
                company: false
            }
        );
    }

    useEffect(() => {
        handleMintNFT();
    }, [signer])

    return (
        <div id="PopUpBuy" style={closed ? { zIndex: "-1" } : { zIndex: "9999" }}>
            <PopUpAlert
                show={popUpForm.show}
                timer={popUpForm.timer}
                closeAnimation={() => { setPopUpForm(popup => ({ ...popup, show: false })) }}
                title={popUpForm.title}
                message={popUpForm.message}
                links={popUpForm.links}
                error={popUpForm.error}
            />
            <AiOutlineCloseCircle className={!closed ? "icon" : "icon off"} onClick={() => { clearInputs(); changePopUpBuyState() }} />
            <div className={!closed ? "direita open" : "direita"}>
                <img src={parte1} alt="" />
            </div>
            <div className={!closed ? "esquerda open" : "esquerda"}>
                <img src={parte2} alt="" />
                <div className="forms">
                    <div className={metamask ? "div show" : "div"}>
                        <h1>Quer usar a Metamask?</h1>
                        <div className="btnsDiv active">
                            <button onClick={metamaskHandler}>Sim</button>
                            <button onClick={() => { setMetamask(false) }}>Não</button>
                        </div>
                    </div>
                    <div className={!metamask && !createWallet ? "div show" : "div"}>
                        <h1 className={!walletInfo.state ? "titles active" : "titles"}>Deseja criar uma carteira?</h1>
                        <div className={!walletInfo.state ? "btnsDiv active" : "btnsDiv"}>
                            <button onClick={() => { setCreateWallet(true) }}>Sim</button>
                            <button onClick={() => { setCreateWallet(false); setWalletInfo({ state: true, addr: "", pk: "", email: "" }) }}>Não</button>
                        </div>
                        <form onSubmit={walletInfoHandler} className={!metamask && !createWallet && walletInfo.state ? "form active" : "form"}>
                            <h1>Dados da sua carteira</h1>
                            <p>Os dados pedidos serão unicamente usados para efetuar a compra dos NFTs e nenhum será guardado.</p>
                            <div className='input-div'>
                                <input required type="email" name='pk-1' id='pk-1' placeholder='' value={walletInfo.email} onChange={event => setWalletInfo({ ...walletInfo, email: event.target.value })} />
                                <label className='input-label' htmlFor="pk-1">Email</label>
                            </div>
                            <div className='input-div'>
                                <input required type="text" name='addr-1' id='addr-1' placeholder='' value={walletInfo.addr} onChange={event => setWalletInfo({ ...walletInfo, addr: event.target.value })} />
                                <label className='input-label' htmlFor="addr-1">Endereço da carteira</label>
                            </div>
                            <div className='input-div'>
                                <input required type="password" name='pk-1' id='pk-1' placeholder='' value={walletInfo.pk} onChange={event => setWalletInfo({ ...walletInfo, pk: event.target.value })} />
                                <label className='input-label' htmlFor="pk-1">Chave Privada</label>
                            </div>
                            <button type="submit">Submeter</button>
                        </form>
                    </div>
                    <div className={!metamask && createWallet ? "div show" : "div"}>
                        <h1 className={!accountInfo.stateCreateAccount && !accountInfo.stateSendEmail ? "titles active" : "titles"}>Deseja criar uma conta na ROOTKey*?</h1>
                        <p>* A ROOTKey é uma plataforma de gestão de ativos na blockchain</p>
                        <div className={!accountInfo.stateCreateAccount && !accountInfo.stateSendEmail ? "btnsDiv active" : "btnsDiv"}>
                            <button onClick={() => {
                                setCreateAccount(true); setAccountInfo({
                                    stateCreateAccount: true,
                                    stateSendEmail: false,
                                    email: "",
                                    password: "",
                                    retypePassword: "",
                                    walletName: "",
                                    userName: "",
                                    description: "",
                                    termes: "false",
                                    company: false
                                })
                            }}>Sim</button>
                            <button onClick={() => {
                                setCreateAccount(true); setAccountInfo({
                                    stateCreateAccount: false,
                                    stateSendEmail: true,
                                    email: "",
                                    password: "",
                                    retypePassword: "",
                                    walletName: "",
                                    userName: "",
                                    description: "",
                                    termes: "false",
                                    company: false
                                })
                            }}>Não</button>
                        </div>
                        {
                            !metamask && createWallet && !walletInfo.state && createAccount && accountInfo.stateCreateAccount && !accountInfo.stateSendEmail ?
                                <form onSubmit={createAccountHandler} className={"form active"}>
                                    <h1>Criação de conta e carteira</h1>
                                    <p>Será criada uma conta na ROOTKey e uma carteira na blockchain, de seguida, receberá um email com todas as informações necessarias para gerir os seus NFTs na plataforma</p>
                                    <div className='input-div'>
                                        <input required type="text" name='Name-1' id='Name-1' placeholder='' value={accountInfo.userName} onChange={event => setAccountInfo({ ...accountInfo, userName: event.target.value })} />
                                        <label className='input-label' htmlFor="Name-1">Nome</label>
                                    </div>
                                    <div className='input-div'>
                                        <input required type="email" name='email-1' id='email-1' placeholder='' value={accountInfo.email} onChange={event => setAccountInfo({ ...accountInfo, email: event.target.value })} />
                                        <label className='input-label' htmlFor="email-1">Email</label>
                                    </div>
                                    <div className='input-div'>
                                        <input required type="password" id="pass-1" name="pass-1" placeholder='' value={accountInfo.password} onChange={event => setAccountInfo({ ...accountInfo, password: event.target.value })} />
                                        <label className='input-label' htmlFor="pass-1">Password</label>
                                    </div>
                                    <div className='input-div'>
                                        <input required type="password" id="pass-ret-1" name="pass-ret-1" placeholder='' value={accountInfo.retypePassword} onChange={event => setAccountInfo({ ...accountInfo, retypePassword: event.target.value })} />
                                        <label className='input-label' htmlFor="pass-ret-1">Reescreva a password</label>
                                    </div>
                                    <div className='input-div'>
                                        <input required type="text" placeholder='' name="walletname" id="walletname" value={accountInfo.walletName} onChange={event => setAccountInfo({ ...accountInfo, walletName: event.target.value })}></input>
                                        <label className='input-label' htmlFor="walletname">Nome da carteira</label>
                                    </div>
                                    <div className='input-div'>
                                        <input type="text" placeholder='' name="walletDesc" id="walletDesc" value={accountInfo.description} onChange={event => setAccountInfo({ ...accountInfo, description: event.target.value })}></input>
                                        <label className='input-label' htmlFor="walletDesc">Descrição da carteira</label>
                                    </div>
                                    <label className="toggle" htmlFor="termos">
                                        <input required type="checkbox" className="toggle__input" id="termos" value={accountInfo.termes} onChange={event => setAccountInfo({ ...accountInfo, termes: event.target.value })} />
                                        <span className="toggle-track">
                                            <span className="toggle-indicator">
                                                <span className="checkMark">
                                                    <svg viewBox="0 0 24 24" id="ghq-svg-check" role="presentation" aria-hidden="true">
                                                        <path d="M9.86 18a1 1 0 01-.73-.32l-4.86-5.17a1.001 1.001 0 011.46-1.37l4.12 4.39 8.41-9.2a1 1 0 111.48 1.34l-9.14 10a1 1 0 01-.73.33h-.01z"></path>
                                                    </svg>
                                                </span>
                                            </span>
                                        </span>
                                        <a href="https://rootkey.ai/legal/terms-conditions" rel="noreferrer" target="_blank">Termos e condições</a>
                                    </label>
                                    <button type="submit">Submeter</button>
                                </form> : accountInfo.stateSendEmail ?
                                    <form onSubmit={createWalletHandler} className={"form active"}>
                                        <h1>Criação de carteira</h1>
                                        <p>Será criada uma carteira na blockchain, de seguida, receberá um email com todas as informações da mesma</p>
                                        <div className='input-div'>
                                            <input required type="text" name='Name-1' id='Name-1' placeholder='' value={accountInfo.userName} onChange={event => setAccountInfo({ ...accountInfo, userName: event.target.value })} />
                                            <label className='input-label' htmlFor="Name-1">Nome</label>
                                        </div>
                                        <div className='input-div'>
                                            <input required type="email" name='email-1' id='email-1' placeholder='' value={accountInfo.email} onChange={event => setAccountInfo({ ...accountInfo, email: event.target.value })} />
                                            <label className='input-label' htmlFor="email-1">Email</label>
                                        </div>
                                        <button type="submit">Submeter</button>
                                    </form> : ""
                        }
                    </div>

                </div>
            </div>
        </div>
    );
};
