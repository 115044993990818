import React from 'react'
import './styles.css'

export default function Footer() {
    return (
        <div id='FooterContainer'>
            <div className='FooterRow'>
                <a rel='noreferrer' target="_blank" href='https://www.rootkey.ai' className='FooterTrademark'>Powered by</a>
                <a rel='noreferrer' target="_blank" href="https://www.rootkey.ai"><img src='https://media.notcybersec.com/Logos_NCS/280x60_branco.png' alt='Logo ROOTKey'/></a>
            </div>
            <p>Leões Porto Salvo © 2022. Direitos Reservados.</p>
        </div>
    )
}