import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {PopUpBuyProvider} from './context/PopUpBuyContext'
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <PopUpBuyProvider>
      <App />
    </PopUpBuyProvider>
  </React.StrictMode>
);