import React, { useState, useEffect } from 'react'
import { BsImageAlt } from 'react-icons/bs'
import { IoFootballOutline } from 'react-icons/io5'
import { BiPurchaseTag, BiBarChartAlt2, BiCollection, BiHome } from 'react-icons/bi'
import { FaQuestionCircle } from 'react-icons/fa'
import { GiLion } from 'react-icons/gi'

import './styles.css'

interface SideBarProps {
  home: boolean
}
export default function SideBar(props: SideBarProps) {

  return (
    <div id='sidebar'>
      {props.home ?
        <>
          <div>
            <a rel='noreferrer' href="#hero"><IoFootballOutline /></a>
            <label htmlFor="1">Topo</label>
          </div>
          <div>
            <a rel='noreferrer' href="#nft" ><BsImageAlt id='2' /></a>
            <label htmlFor="2" className='label-7'>Sobre NFTs</label>
          </div>
          <div>
            <a rel='noreferrer' href="#comprar"><BiPurchaseTag id='3' /></a>
            <label htmlFor="3">Comprar</label>
          </div>
          <div>
            <a rel='noreferrer' href="#raridade"><BiBarChartAlt2 id='4' /></a>
            <label htmlFor="4">Raridades</label>
          </div>
          <div>
            <a rel='noreferrer' href="#faq"><FaQuestionCircle id='5' /></a>
            <label htmlFor="5">FAQ</label>
          </div>
          <div>
            <a rel='noreferrer' href="/caderneta"><BiCollection id='6' /></a>
            <label htmlFor="6">Caderneta</label>
          </div>
        </>
        :
        <>
          <div>
            <a rel='noreferrer' target="_blank" href="https://www.leoesdeportosalvo.pt/"><GiLion id='7' /></a>
            <label className='label-7' htmlFor="7">LPS Website</label>
          </div>
          <div>
            <a rel='noreferrer' href="/"><BiHome id='8' /></a>
            <label htmlFor="8">Home</label>
          </div>

        </>}
    </div>
  )
}