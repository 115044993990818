import { createContext, useEffect, useState, ReactNode } from 'react';

interface PopUpBuyProps {
    changePopUpBuyState: () => void;
    closed: boolean;
}

interface PopUpBuyProviderProps {
    children: ReactNode;
}

export const PopUpBuyContext = createContext({} as PopUpBuyProps)

export function PopUpBuyProvider({ children }: PopUpBuyProviderProps) {
    const [closed, isClosed] = useState<boolean>(true);

    function changePopUpBuyState() {
        isClosed(state => !state);
    }

    return (
        <PopUpBuyContext.Provider value={{
            changePopUpBuyState,
            closed
        }}>
            {children}
        </PopUpBuyContext.Provider>
    )
}