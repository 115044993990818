import React from "react";
import "./styles.css";
import bg from "../../assets/lojaoficial1-1.jpg";

export default function Hero() {
  return (
    <div id="hero">
        <img src={bg} alt="bg" />
        <div className="line">
          <h1>BEM VINDO AO FUTURO!</h1>
          <h1>Coleção de NFTs Leões Porto Salvo</h1>
          <h2>A formar os homens e mulheres do amanhã </h2>
        </div>
    </div>
  )
};
