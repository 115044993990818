import React, { useEffect } from 'react'
import './styles.css'
import AOS from 'aos';
import 'aos/dist/aos.css';

import nft from '../../assets/image_2.png'
export default function NFT() {
    
    useEffect(() => {
            AOS.init({ duration: 1000 });
    }, [])
    return (
        <div id='nft' >
            <div className='container'>
                <div className='left'>
                    <h1 data-aos="fade-up" className='title'>O que são NFT's</h1>
                    <h3 data-aos="fade-up">
                        Os Non-fungible tokens, ou NFTs, são conteúdos digitais ligados à blockchain, a base de dados digital subjacente às moedas criptográficas, tais como a Bitcoin e a Ethereum.
                        <br /><br />Ao contrário dos NFT, esses activos são fungíveis, o que significa que podem ser substituídos ou trocados por outro idêntico do mesmo valor, muito semelhante a uma nota de dólar.
                        <br /><br />Os NFT, por outro lado, são únicos e não permutáveis entre si, o que significa que não há dois NFT iguais.
                    </h3>
                </div>
                <img data-aos="fade-left" src={nft} alt="" />
            </div>
        </div>
    )
}