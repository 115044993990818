import React, { useState, useEffect } from "react";
import saqueta from '../../assets/carteira2.png'
import "./styles.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { usePopUpBuy } from "hook/usePopUpBuy";
type Window = {
  ethereum?: any;
}

declare const windowEth: Window;

export default function Buy() {
  const [animation, setAnimation] = useState<boolean>(true)
  const {changePopUpBuyState} = usePopUpBuy();

  function animations():void {
    if (window.innerWidth < 968){
      setAnimation(false)
    }
  }

  useEffect(() => {
		AOS.init({ duration: 1000 });
    animations()
  }, [])

  return (
    <div id="comprar">
      <div className="container">

        <div className="line">
          <div className="col img">
            <img data-aos="fade-right" src={saqueta} alt="1" />
          </div>
          <div data-aos={!animation ? "fade-right" : ""} className="col">
            <h1 data-aos={animation ? "fade-up" : ""} className='title'>Comprar Saqueta</h1>
            <h1 data-aos={animation ? "fade-up" : ""}> Saqueta de cromos dos LPS</h1>
            <h4 data-aos={animation ? "fade-up" : ""}> Cada saqueta contem 5 cromos, cada um deles representa 1 NFT. Estes serão automaticamente adicionados à tua caderneta digital logo após a confirmação do pagamento.</h4>
            <h3 data-aos={animation ? "fade-up" : ""}> Preço: 5 euros</h3>
            <button data-aos={animation ? "fade-up" : ""} className="CardBtn" onClick={() => {changePopUpBuyState()}}>Comprar</button>
            </div>
        </div>
      </div>
    </div>
  );
};