import React, { useState } from 'react';

import './styles.css';


interface PopUpProps {
    error?:boolean
    title?:string
    message?:string
    links?: string
    timer:number
    closeAnimation: () => void;
    show:boolean
}
export default function PopUpAlert(props : PopUpProps) {
    const [show, setShow] = useState(false);
    
    setTimeout(function () {
        if (!show && props.show) { 
            setShow(true); 
        }
    }, 1000);

    setTimeout(function () {
        if (show && props.show) { 
            setShow(false); 
            props.closeAnimation()
        }
    }, props.timer);

    function getColor():string {

        if (props.error) {
            return "var(--red)"
        }

        return "var(--green)"
    }
	return (
		<div id='PopUpAlert'>
            <div className={show ? 'container-1 active' : 'container-1'} 
            style={{background: `${getColor()}`}}>

                {props.title ? <h3>{props.title} </h3> : ""}
                {props.message ? <h4>{props.message}</h4> : ""}
                {props.links ? <a href={props.links} target='_blank' rel="noreferrer">{props.links}</a> : ""}
            </div>
        </div>
    )
}