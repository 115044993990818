import React, { useEffect } from 'react'
import './styles.css'
import nft1 from '../../assets/raridade_1.png'
import nft2 from '../../assets/raridade_2.png'
import nft3 from '../../assets/raridade_3.png'
import nft4 from '../../assets/raridade_4.png'
import nft5 from '../../assets/raridade_5.png'
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function Raridade() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, [])
  return (
    <div id='raridade'>
      <div className='container'>
        <h1 className='title'>Raridade dos cromos</h1>

        <div className='grid'>
          <div data-aos="fade-up">
            <img src={nft1} alt="" />
            <h1> 48%</h1>
          </div>
          <div data-aos="fade-up">
            <img src={nft4} alt="" />
            <h1> 19%</h1>
          </div>
          <div data-aos="fade-up">
            <img src={nft5} alt="" />
            <h1> 1%</h1>
          </div>
          <div data-aos="fade-up">
            <img src={nft3} alt="" />
            <h1> 8%</h1>
          </div>
          <div  data-aos="fade-up">
            <img src={nft2} alt="" />
            <h1> 21%</h1>
          </div>
        </div>
      </div>
    </div>

  )
}