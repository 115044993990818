import React from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import './styles.css';

export interface PopUpInfo {
    name?: string;
    number?: string;
    imageURI?: string;
    position: string;
    age: string;
    rarity?: string;
    onClick?: () => void;
}

export function PopUp(info: PopUpInfo) {
    return (
        <div id="modal" >
            <AiOutlineCloseCircle className='icon' onClick={info.onClick} />

            <div className="popUp" >
                <div className="container-1">
                    <img src={info.imageURI} alt={info.name} />
                    <div className="statistics">
                        <div className="chars">
                            <label htmlFor="name"></label>
                            <span className="info name" id='name'>{info.name}</span>
                        </div>
                        <div className="chars">
                            <label htmlFor="num">Número:</label>
                            <span className="info number" id='num'>{info.number}</span>
                        </div>
                        <div className="chars">
                            <label htmlFor="age">Idade:</label>
                            <span className="info age" id='age'>{info.age}</span>
                        </div>
                        <div className="chars">
                            <label htmlFor="pos">Posição: </label>
                            <span className="info position" id='pos'>{info.position}</span>
                        </div>
                        <div className="chars">
                            <label htmlFor="rar">Raridade:</label>
                            <span className="info rarity" id='rar'>{info.rarity}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}